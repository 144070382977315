import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { Role, Page } from "@/types";

type RoleState = {
  rolePage: Page<Role>;
};

const role: Module<RoleState, unknown> = {
  namespaced: true,
  state: () => ({
    rolePage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getRole: (state) => (roleId: string) =>
      state.rolePage.docs.find((c) => c._id === roleId),
    rolePage: (state) => state.rolePage,
  },
  mutations: {
    ADD_ROLE: (state, _role) => {
      let idx = -1;
      state.rolePage.docs.map((r, i) => {
        if (r._id === _role._id) idx = i;
      });
      if (idx === -1) state.rolePage.docs.push(_role);
      else Vue.set(state.rolePage.docs, idx, _role);
    },
    SET_ROLE_PAGE: (state, list) => {
      state.rolePage = list;
    },
    REMOVE_ROLE(state, role) {
      let idx = -1;
      state.rolePage.docs.map((r, i) => {
        if (r._id === role._id) idx = i;
      });
      if (idx > -1) state.rolePage.docs.splice(idx, 1);
    },
  },
  actions: {
    fetchRole(context, params = "") {
      api
        .get(`/v1/role${params}`)
        .then((response) => {
          context.commit("ADD_ROLE", response.data.role);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchRoleList(context, params = "") {
      api
        .get(`/v1/role${params}`)
        .then((response) => {
          context.commit("SET_ROLE_PAGE", response.data.rolePage);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createRole(context, payload) {
      return await api
        .post(`/v1/role`, payload)
        .then((response) => {
          context.commit("ADD_ROLE", response.data.role);
          // context.dispatch(
          //   "setToast",
          //   {
          //     title: "Success",
          //     type: "success",
          //     text: "Role created",
          //   },
          //   { root: true }
          // );
          return response.data.role;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    updateRole(context, data: { id: string; role: Role }) {
      api
        .put(`/v1/role/${data.id}`, data.role)
        .then((response) => {
          context.commit("ADD_ROLE", response.data.role);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Role updated",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    deleteRole(context, id) {
      api
        .delete(`/v1/role${id}`)
        .then((response) => {
          context.commit("REMOVE_ROLE", response.data.role);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Role deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default role;
