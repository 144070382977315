var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-container"},[_c('div',{staticClass:"mb-4 d-md-none"},[_c('v-img',{attrs:{"src":require("../../assets/logo.png"),"width":"150px"}})],1),_c('h3',{staticClass:"text-md-h4 text-h5"},[_vm._v("Sign up")]),_c('br'),(false)?_c('div',{staticClass:"auth-btns"},[_c('v-btn',{staticClass:"btn",attrs:{"tile":"","outlined":"","large":"","color":"light"}},[_vm._v(" Sign in with Google ")]),_c('v-btn',{staticClass:"btn",attrs:{"tile":"","outlined":"","large":"","color":"light"}},[_vm._v(" Sign in with facebook ")])],1):_vm._e(),(false)?_c('div',{staticClass:"divider my-2",attrs:{"hidden":""}},[_c('div',{staticClass:"divider-text"},[_vm._v("Or")])]):_vm._e(),_c('v-form',{ref:"signUpForm",staticClass:"sign-up-form",attrs:{"data-app":""},on:{"submit":function($event){$event.preventDefault();return _vm.validateSignUp.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"rules":_vm.nameRules,"name":"FullName","label":"Full Name","type":"text","required":""},model:{value:(_vm.displayName),callback:function ($$v) {_vm.displayName=$$v},expression:"displayName"}}),_c('v-menu',{ref:"dateMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":_vm.dobRules,"name":"Date of Birth","label":"Date of Birth","prepend-inner-icon":"mdi-calendar-blank","hint":"DD/MM/YYYY format","persistent-hint":""},on:{"blur":function($event){_vm.dob = _vm.parseDate(_vm.dateFormatted)}},model:{value:(_vm.dateFormatted),callback:function ($$v) {_vm.dateFormatted=$$v},expression:"dateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.dateFormatted = _vm.formatDate(_vm.dob);
          _vm.dateMenu = false;}},model:{value:(_vm.dob),callback:function ($$v) {_vm.dob=$$v},expression:"dob"}})],1),_c('v-radio-group',{attrs:{"rules":_vm.genderRules,"name":"Gender","label":"Gender","required":""},model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}},[_c('v-radio',{attrs:{"label":"Male","value":"Male"}}),_c('v-radio',{attrs:{"label":"Female","value":"Female"}})],1),_c('v-text-field',{attrs:{"rules":_vm.emailRules,"name":"Email","label":"Email Address","type":"text","required":"","disabled":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-select',{attrs:{"rules":_vm.countryRules,"items":_vm.countries,"name":"Country","label":"Country","type":"text","required":""},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}}),_c('v-text-field',{attrs:{"rules":_vm.passwordRules,"name":"Password","label":"Password","type":"password","required":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-text-field',{attrs:{"rules":_vm.passwordConfirmationRules,"label":"Password Confirmation","type":"password","required":""},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('v-checkbox',{attrs:{"rules":_vm.termsRules},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" I agree to the Platform "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('a',_vm._g({attrs:{"target":"_blank","href":"#"},on:{"click":function($event){$event.stopPropagation();}}},on),[_vm._v(" Terms of service ")])]}}])},[_vm._v(" View in new tab ")]),_vm._v(" and "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('a',_vm._g({attrs:{"target":"_blank","href":"#"},on:{"click":function($event){$event.stopPropagation();}}},on),[_vm._v(" Privacy Policy ")])]}}])},[_vm._v(" View in new tab ")])],1)]},proxy:true}]),model:{value:(_vm.agreedToTerms),callback:function ($$v) {_vm.agreedToTerms=$$v},expression:"agreedToTerms"}}),(!_vm.submitted)?_c('v-btn',{attrs:{"type":"submit","tile":"","large":"","color":"primary"}},[_vm._v(" Register ")]):_c('v-btn',{attrs:{"type":"button","tile":"","large":"","color":"primary"}},[_vm._v(" Working. Please wait "),_c('v-progress-circular',{attrs:{"color":"#fff","indeterminate":""}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }