




























































































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import * as firebase from "firebase/auth";
import moment from "moment-timezone";
const tz = localStorage.getItem("tz") ?? "Africa/Nairobi";

import employeeStoreModule from "@/store/modules/employee";
import roleStoreModule from "@/store/modules/role";
import { Business, Employee, User } from "@/types";
import { DATE_REGEX } from "@/util/constants";
import { setAuth } from "@/util/auth";

const { mapActions: employeeActions } =
  createNamespacedHelpers("EMPLOYEE_SIGNUP");
const { mapActions: roleActions } = createNamespacedHelpers("ROLE");
export default Vue.extend<any, any, any, any>({
  name: "EmployeeSignUp",
  props: {
    employeeId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    valid: false,
    dateDialog: false,
    agreedToTerms: false,
    termsRules: [(v: boolean) => v || "Agree to terms"],
    password: "",
    passwordRules: [
      (v: string) => !!v || "Password is required",
      (v: string) =>
        v.length >= 6 || "Password must be greater than 6 characters",
    ],
    confirmPassword: "",
    email: "",
    emailRules: [
      (v: string) => !!v || "E-mail is required",
      (v: string) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    displayName: "",
    nameRules: [(v: string) => !!v || "Name is required"],
    dob: "",
    dobRules: [
      (v: string) => !!v || "Date of Birth is required",
      (v: string) => DATE_REGEX.test(v) || "Date of birth must be valid format",
    ],
    gender: "",
    genderRules: [(v: string) => !!v || "Gender is required"],
    user: undefined as undefined | User,
    country: "",
    countries: ["Australia", "Kenya"],
    countryRules: [(v: string) => !!v || "Country is required"],
    dateFormatted: "",
    dateMenu: false,
    submitted: false,
  }),
  created() {
    this.fetchEmployee(`?employeeId=${this.employeeId}`);
  },
  computed: {
    employee(): Employee {
      return this.$store.getters["EMPLOYEE_SIGNUP/getEmployee"](
        this.employeeId
      );
    },
    passwordConfirmationRules() {
      return [
        (v: string) => !!v || "Password Confrimation is required",
        () => this.password === this.confirmPassword || "Password must match",
      ];
    },
  },
  watch: {
    employee: "setData",
  },
  methods: {
    ...employeeActions(["fetchEmployee", "updateEmployee"]),
    ...roleActions(["createRole"]),
    validateSignUp() {
      const valid = (
        this.$refs.signUpForm as Element & { validate: () => boolean }
      )?.validate();

      if (valid) {
        this.register();
      }
    },
    register() {
      this.submitted = true;
      this.$store.dispatch("setLoadingRequest", true);
      firebase
        .createUserWithEmailAndPassword(
          firebase.getAuth(),
          this.email,
          this.password
        )
        .then((data) => data.user.getIdToken())
        .then((token) => {
          let date: string | string[] = this.dateFormatted;

          date = (date as string).split("/");

          date = `${date[1]}-${date[0]}-${date[2]}`;
          this.$store.dispatch("setLoadingRequest", false);
          setAuth({ token }, false);
          this.$store
            .dispatch("register", {
              displayName: this.displayName,
              dob: new Date(date).toISOString(),
              gender: this.gender,
              preferences: ["none"],
              country: this.country,
            })
            .then((user) => {
              if (user) {
                this.user = user;
                this.createRole({
                  businessId: this.employee.business._id,
                  userId: user._id,
                }).then((role) => {
                  if (role) {
                    this.submitted = false;
                    setAuth({}, true);
                  }
                });
              }
            });
        })
        .catch((error) => {
          this.$store.dispatch("setLoadingRequest", false);
          this.$store.dispatch("setToast", {
            title: "Request Failed!",
            type: "error",
            text: error.message,
          });
          this.submitted = false;
          console.log(error.message);
        });
    },
    formatDate(date: string) {
      if (!date) return null;

      return moment(date).tz(tz).format("DD/MM/YYYY");
    },
    parseDate(date: string) {
      if (!date) return null;
      let d: string | string[] = date.split("/");

      d = `${d[2]}-${d[1]}-${d[0]}`;

      return moment(d).tz(tz).format("YYYY-MM-DD");
    },
    setData() {
      if (this.employee) {
        this.displayName = this.employee.fullName;
        this.email = this.employee.email;
        this.gender = this.employee.gender;
        this.dateFormatted = moment(this.employee.dob)
          .tz(tz)
          .format("DD/MM/YYYY");
        this.country =
          (this.employee.business as Business).countryAlpha3Code === "KEN"
            ? "Kenya"
            : "Australia";
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("EMPLOYEE_SIGNUP")) {
      this.$store.registerModule("EMPLOYEE_SIGNUP", employeeStoreModule);
    }
    if (!this.$store.hasModule("ROLE")) {
      this.$store.registerModule("ROLE", roleStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("EMPLOYEE_SIGNUP");
    this.$store.unregisterModule("ROLE");
  },
});
